<template>
  <div>
    <b-row v-if="!isLoading">
      <b-col md="12">
        <div class="pb-3 pt-3">
          <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">{{pFirstName}} {{pLastName}} member 1 health & lifestyle</h3>

          <b-row>
            <b-col xl="10" lg="12" md="12" sm="12">
              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Have you used tobacco or nicotine products in the last 12 months?</span>
                      <div class="ml-1 d-inline-block" :id="`p-smoker-detail`">
                        <span class="not-sure" @click="() => showPSmoker = !showPSmoker" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>  
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showPSmoker" class="mt-3">
                    <p>Insurance companies categorize an individual as a smoker if they have used cigarettes, cigars, pipes, or any other tobacco-related products (including nicotine replacements and e-cigarettes) within the preceding 12 months.</p>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle flex-wrap"
                  v-model="pIsSmoker"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="w-130px d-flex mb-2"
                    v-for="(item,i) in ynOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == pIsSmoker ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == pIsSmoker"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != pIsSmoker"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Do you have an ongoing or have had a medical condition in the past 5 years?</span>
                      <div class="ml-1 d-inline-block" :id="`p-ongoing-detail`">
                        <span class="not-sure" @click="() => showPMedical = !showPMedical" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showPMedical" class="mt-3">
                    <p>Your premium may be impacted by medical conditions that you have disclosed in your application, such as high blood pressure, raised cholesterol, diabetes, anxiety, depression, or any other relevant medical conditions.</p>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle flex-wrap"
                  v-model="pPastOngoingCondition"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="w-130px d-flex mb-2"
                    v-for="(item,i) in ynnOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == pPastOngoingCondition ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == pPastOngoingCondition"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != pPastOngoingCondition"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Do you have high risk occupation or hobby?</span>
                      <div class="ml-1 d-inline-block" :id="`p-risky-detail`">
                        <span class="not-sure" @click="() => showPRisk = !showPRisk" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showPRisk" class="mt-3">
                    <p>Engaging in certain high-risk occupations or hobbies may have an impact on your premium, such as working at heights or with water, being a member of the Armed Forces, having involvement in offshore oil and gas activities, or participating in hazardous pursuits like climbing or extreme sports.</p>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle flex-wrap"
                  v-model="pRiskOccupation"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="w-130px d-flex mb-2"
                    v-for="(item,i) in ynnOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == pRiskOccupation ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == pRiskOccupation"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != pRiskOccupation"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group label="How tall are you?">
                <b-row>
                  <b-col><b-form-input placeholder="feet" v-model="pHeightFeet"/></b-col>
                  <b-col><b-form-input placeholder="inches" v-model="pHeightInch"/></b-col>
                  <b-col md="1">or</b-col>
                  <b-col><b-form-input placeholder="m" v-model="pHeightM"/></b-col>
                </b-row>
              </b-form-group>
              
              <b-form-group>
                <template slot="label">
                  <div>How much do you weight?</div>
                  <div class="small-label">If you are pregnant, please tell us your weight before you were pregnant.</div>
                </template>
                <b-row>
                  <b-col><b-form-input placeholder="st" v-model="pWeightSt"/></b-col>
                  <b-col><b-form-input placeholder="lbs" v-model="pWeightLbs"/></b-col>
                  <b-col md="1">or</b-col>
                  <b-col><b-form-input placeholder="kg" v-model="pWeightKg"/></b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="pb-3 pt-3" v-if="policyType == 'joint'">
          <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">{{sFirstName}} {{sLastName}} health & lifestyle</h3>

          <b-row>
            <b-col xl="10" lg="12" md="12" sm="12">
              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Have you used tobacco or nicotine products in the last 12 months?</span>
                      <div class="ml-1 d-inline-block" :id="`s-smoker-detail`">
                        <span class="not-sure" @click="() => showSSmoker = !showSSmoker" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showSSmoker" class="mt-3">
                    <p>Insurance companies categorize an individual as a smoker if they have used cigarettes, cigars, pipes, or any other tobacco-related products (including nicotine replacements and e-cigarettes) within the preceding 12 months.</p>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle flex-wrap"
                  v-model="sIsSmoker"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="w-130px d-flex mb-2"
                    v-for="(item,i) in ynOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == sIsSmoker ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == sIsSmoker"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != sIsSmoker"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Do you have an ongoing or have had a medical condition in the past 5 years?</span>
                      <div class="ml-1 d-inline-block" :id="`s-ongoing-detail`">
                        <span class="not-sure" @click="() => showSMedical = !showSMedical" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showSMedical" class="mt-3">
                    <p>Your premium may be impacted by medical conditions that you have disclosed in your application, such as high blood pressure, raised cholesterol, diabetes, anxiety, depression, or any other relevant medical conditions.</p>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle flex-wrap"
                  v-model="sPastOngoingCondition"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="w-130px d-flex mb-2"
                    v-for="(item,i) in ynnOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == sPastOngoingCondition ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == sPastOngoingCondition"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != sPastOngoingCondition"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Do you have high risk occupation or hobby?</span>
                      <div class="ml-1 d-inline-block" :id="`s-risky-detail`">
                        <span class="not-sure" @click="() => showSRisk = !showSRisk" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showSRisk" class="mt-3">
                    <p>Engaging in certain high-risk occupations or hobbies may have an impact on your premium, such as working at heights or with water, being a member of the Armed Forces, having involvement in offshore oil and gas activities, or participating in hazardous pursuits like climbing or extreme sports.</p>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle flex-wrap"
                  v-model="sRiskOccupation"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="w-130px d-flex mb-2"
                    v-for="(item,i) in ynnOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == sRiskOccupation ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == sRiskOccupation"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != sRiskOccupation"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group label="How tall are you?">
                <b-row>
                  <b-col><b-form-input placeholder="feet" v-model="sHeightFeet"/></b-col>
                  <b-col><b-form-input placeholder="inches" v-model="sHeightInch"/></b-col>
                  <b-col md="1">or</b-col>
                  <b-col><b-form-input placeholder="m" v-model="sHeightM"/></b-col>
                </b-row>
              </b-form-group>
              
              <b-form-group>
                <template slot="label">
                  <div>How much do you weight?</div>
                  <div class="small-label">If you are pregnant, please tell us your weight before you were pregnant.</div>
                </template>
                <b-row>
                  <b-col><b-form-input placeholder="st" v-model="sWeightSt"/></b-col>
                  <b-col><b-form-input placeholder="lbs" v-model="sWeightLbs"/></b-col>
                  <b-col md="1">or</b-col>
                  <b-col><b-form-input placeholder="kg" v-model="sWeightKg"/></b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-button class="large-button" variant="primary" v-on:click="getProtectionQuotes" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.twoPageQuote ? "Next" : "Get Quote" }}</b-button>
        </div>
      </b-col>

    </b-row>
     <quote-loading v-if="isLoading"/>
  </div>
</template>

<script>
import QuoteLoading from "@/view/pages/protection/quote-form/QuoteLoading.vue";

export default {
  components: {
    QuoteLoading
  },
  data(){
    return {
      showPSmoker: false,
      showSSmoker: false,
      showPMedical: false,
      showSMedical: false,
      showPRisk: false,
      showSRisk: false,
      askingMode: '',
      ynOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      ynnOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
        { text: "Not Sure", value: 2 }
      ]
    }
  },
  methods: {
    updateDetails: function(data){
      this.askingMode == "application" ? this.$store.commit("updateApplicationDetails", data) : this.$store.commit("updateQuoteForm", data);
    },
    getProtectionQuotes: function(){
      console.log("This called");
      var _this = this;
      this.$resetFrame();
      this.$validator.validateAll().then(result => {
        if(result){
          this.$store.commit("updateViewMode", false);
          this.$store.commit("updateEditMode", false);
          
          if(!this.$store.getters.twoPageQuote){
            // Check if form has text validation
            this.$store.dispatch('hasVerification', { phoneNumber: this.telephoneNumber }).then(res => {
              console.log(res);
              if(res.data.hasTextVerification){
                this.otpReference = res.data.reference;

                // Fetch quotes on background
                this.fetchOtpQuotes();

                this.$swal.fire({
                  position: 'top',
                  showClass: {
                    popup: 'swal2-show mt-25',
                  },
                  title: 'Verification',
                  html: `Enter the OTP code you have received.<br/><small>Code expires in 5 minutes.</small>`,
                  input: 'number',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Verify',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showDenyButton: true,
                  denyButtonText: 'Resend Code',
                  denyButtonColor: '#369A6E',
                  didOpen: () => {
                    this.isOtpWaiting = true;
                  },
                  preDeny: () => {
                    return new Promise(function(){
                      _this.$store.dispatch('hasVerification', { phoneNumber: _this.telephoneNumber, reference: _this.otpReference }).then(res => {
                        _this.$swal.enableButtons();
                        _this.otpReference = res.data.reference;
                      });
                    });
                  },
                  preConfirm: (otp) => {
                    return new Promise(function(resolve){
                      _this.$store.dispatch('verifyOtp', {
                        otp: otp,
                        reference: _this.otpReference
                      }).then(response => {
                        if(response.data.success){
                          resolve();
                          _this.$router.push({ name: 'PMIQuoteResult' });
                          _this.$store.commit("updateLoadingStatus", false);
                        } else {
                          _this.$swal.showValidationMessage("Invalid OTP code.");
                          _this.$swal.enableButtons();
                        }
                      });
                    });
                  }
                }).then((result) => {
                  if(result.dismiss == 'cancel'){
                    this.isOtpWaiting = false;
                    this.$store.commit("updateQuoteResult",[]);
                  }
                });
              } else {
                this.fetchQuotes(); 
              }
            }).catch(err => {
              console.log(err);
              if(this.$store.state.pmi.displayMode == 'demo'){
                this.fetchQuotes();
              }
            });
          } else {
            this.$router.push({ name: 'ProtectionQuoteResult' });
            this.$store.commit("updateLoadingStatus", false);
            this.$resizeFrame();
          }
          
        }
      });
    },
    fetchQuotes: function(){
      this.$store.commit("updateLoadingStatus", true);
      this.$store.dispatch("getProtectionQuoteProducts").then(quotes => {
        console.log(quotes);
        var pluginMessage = { 
          messageType: "quoteResults",
          payload: {
            clientDetails: this.$formatProtection(this.quoteForm),
            quoteResult: quotes
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        this.$router.push({ name: 'ProtectionQuoteResult' });
        this.$store.commit("updateLoadingStatus", false);
      })
      .catch(() => {
        this.$store.commit("updateLoadingStatus", false);
      });
    },
  },
  computed: {
    // Primary member
    pFirstName: {
      get() {
        return this.quoteForm.primaryMember.firstName;
      }
    },
    pLastName: {
      get() {
        return this.quoteForm.primaryMember.lastName;
      }
    },
    pIsSmoker: {
      get() {
        return this.quoteForm.primaryMember.isSmoker;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.isSmoker = value;
        this.updateDetails(quoteForm);
      }
    },
    pPastOngoingCondition: {
      get() {
        return this.quoteForm.primaryMember.pastOngoingCondition;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.pastOngoingCondition = value;
        this.updateDetails(quoteForm);
      }
    },
    pRiskOccupation: {
      get() {
        return this.quoteForm.primaryMember.riskOccupation;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.riskOccupation = value;
        this.updateDetails(quoteForm);
      }
    },
    pHeightFeet: {
      get() {
        return this.quoteForm.primaryMember.heightFeet;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.heightFeet = value;
        this.updateDetails(quoteForm);
      }
    },
    pHeightInch: {
      get() {
        return this.quoteForm.primaryMember.heightInch;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.heightInch = value;
        this.updateDetails(quoteForm);
      }
    },
    pHeightM: {
      get() {
        return this.quoteForm.primaryMember.heightM;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.heightM = value;
        this.updateDetails(quoteForm);
      }
    },
    pWeightKg: {
      get() {
        return this.quoteForm.primaryMember.weightKg;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.weightKg = value;
        this.updateDetails(quoteForm);
      }
    },
    pWeightSt: {
      get() {
        return this.quoteForm.primaryMember.weightSt;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.weightSt = value;
        this.updateDetails(quoteForm);
      }
    },
    pWeightLbs: {
      get() {
        return this.quoteForm.primaryMember.weightLbs;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.weightLbs = value;
        this.updateDetails(quoteForm);
      }
    },
    // Secondary member
    sFirstName: {
      get() {
        return this.quoteForm.secondaryMember.firstName;
      }
    },
    sLastName: {
      get() {
        return this.quoteForm.secondaryMember.lastName;
      }
    },
    sIsSmoker: {
      get() {
        return this.quoteForm.secondaryMember.isSmoker;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.isSmoker = value;
        this.updateDetails(quoteForm);
      }
    },
    sPastOngoingCondition: {
      get() {
        return this.quoteForm.secondaryMember.pastOngoingCondition;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.pastOngoingCondition = value;
        this.updateDetails(quoteForm);
      }
    },
    sRiskOccupation: {
      get() {
        return this.quoteForm.secondaryMember.riskOccupation;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.riskOccupation = value;
        this.updateDetails(quoteForm);
      }
    },
    sHeightFeet: {
      get() {
        return this.quoteForm.secondaryMember.heightFeet;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.heightFeet = value;
        this.updateDetails(quoteForm);
      }
    },
    sHeightInch: {
      get() {
        return this.quoteForm.secondaryMember.heightInch;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.heightInch = value;
        this.updateDetails(quoteForm);
      }
    },
    sHeightM: {
      get() {
        return this.quoteForm.secondaryMember.heightM;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.heightM = value;
        this.updateDetails(quoteForm);
      }
    },
    sWeightKg: {
      get() {
        return this.quoteForm.secondaryMember.weightKg;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.weightKg = value;
        this.updateDetails(quoteForm);
      }
    },
    sWeightSt: {
      get() {
        return this.quoteForm.secondaryMember.weightSt;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.weightSt = value;
        this.updateDetails(quoteForm);
      }
    },
    sWeightLbs: {
      get() {
        return this.quoteForm.secondaryMember.weightLbs;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.weightLbs = value;
        this.updateDetails(quoteForm);
      }
    },
    policyType: {
      get(){
        return this.quoteForm.policyType;
      }
    },
    quoteForm: {
      get() {
        return this.$store.state.protection.quoteForm;
      }
    },
    isLoading: {
      get() {
        return this.$store.state.pmi.isLoading;
      }
    }
  }
}
</script>

<style>
.small-label {
  font-size: 11px
}

.not-sure {
  cursor: pointer;
}

.w-130px {
  width: 130px;
}
</style>